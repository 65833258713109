import React, { useState, useEffect, createRef } from "react"
import { Link as GatsbyLink } from 'gatsby'
import LayoutAccount from "../components/layouts/layoutAccount"
import { Box, Button, Flex, Heading, Image, Spinner, Text } from "@chakra-ui/react"
import { FormattedMessage } from 'react-intl';
import Seo from '../components/Seo/';
import logo from '../images/logo.svg'
import withLocation from "../utils/withLocation";
import GetAuthLinkForm from '../components/GetAuthLinkForm'
import AuthService from '../../services/auth.service'
// const siteConfig = require('../../config')

const AccountLoginTpl = (props) => {

  useEffect(() => getLoginCredentials(), []);

  const [email, setEmail] = useState(null)

  const getLoginCredentials = () => {
    if (props.search.token) {
      AuthService.login(props.search.token, props.search.redirect).then((data) => {
        // console.log('auth', data)
      })
    }
  }

  const title = {
    "fr": "Votre compte, gestion de vos annonce"
    ,
    "en": "Your account"
  }
  const description = {
    "fr": "Voici le formulaire de Wanaboat qui va vour permettre de mettre en vente votre bateau en quelques minutes à peine."
    ,
    "en": "Here is the iWannaboat form that will allow you to put your boat up for sale in just a few minutes."
  }

  const emailField = createRef()

  return (
    <LayoutAccount lang={process.env.GATSBY_LANG}>
      <Seo
        title={
          title[process.env.GATSBY_LANG]
        }
        description={
          description[process.env.GATSBY_LANG]

        }
        slug={props.pageContext.slug}
      />
      <Box>
        <Flex
          p='2rem'
          borderBottom={{ base: 'none', lg: 'solid 1px' }}
          borderBottomColor={{ lg: 'gray.100' }}
          justifyContent='space-between'
        >
          <Box>
            <Button as={GatsbyLink} to="/">
              <FormattedMessage id="main.back.to_webiste" />
            </Button>
          </Box>
          <Box>
            <Image src={logo} w="50px" h="50px" />
          </Box>
        </Flex>

        {!props.search.token ?
          <Box
            mt={10}
            px={8}
          >
            <GetAuthLinkForm
              redirect={process.env.GATSBY_LANG === 'fr' ? '/capitainerie/compte/' : '/harbour-office/account/'}
            />
          </Box>
          :
          <Box
            key={0}
            height="80px"
            p={10}
            mx='auto'
            borderRadius="sm"
            justify="center"
            bg="white"
            alignItems="center"
          >
            <Spinner size='sm' mr='1rem' color="blue.600" />
            {/* <FormattedMessage id="account.login.verification" />&nbsp;<Text as="span" textDecoration="line-through">vaccination</Text>&nbsp;navigation */}
          </Box>
        }

      </Box>
    </LayoutAccount>
  )
}

export default withLocation(AccountLoginTpl)