import React, { useState, useEffect, createRef } from 'react'
import * as yup from 'yup';
import AuthService from '../../services/auth.service'
import { Formik, Form, Field } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  Stack,
  Text,
  FormHelperText,
  FormErrorMessage
} from '@chakra-ui/react'
import axios from 'axios'
import Debug from '../utils/Debug';

const GetAuthLinkForm = ({ redirect }) => {
  const locale = useIntl()['locale']
  const emailField = createRef()
  const nameField = createRef()

  const [emailValue, setEmailValue] = useState('')
  const [nameValue, setNameValue] = useState('')

  const [isRequestSent, setRequestSent] = useState(false)
  const [knownUser, setKnownUser] = useState(false)
  const [userCheck, setUserCheck] = useState(false)
  const handleLoginSubmit = (e) => {
    e.preventDefault();
    AuthService.sendAuthLink(emailField.current.value, redirect).then((response) => {
      console.log(response)
      if (!response) {
      } else {
        setRequestSent(true)
      }
    }
    )
  }

  const checkEmail = (email) => {
    // console.log(e.target.value)
    axios.get(`${process.env.GATSBY_API_URL}/user/email/${email}`)
      .then((response) => {
        console.log('response', response)
        if (response.data === false) {
          setKnownUser(false)
          setUserCheck(true)
        } else {
          setKnownUser(true)
          setUserCheck(true)

        }
      })
  }

  return (
    <Stack maxW='900px' mx='auto' spacing="2rem">
      <Box>

        {!isRequestSent ?
          <Formik
            initialValues={{ email: '' }}
            validate={(values) => checkEmail(values.email)}
            validationSchema={
              yup.object().shape({
                email: yup.string().email('Invalid email').required('Required'),
              })
            }
            onSubmit={(values) => {

              if (knownUser && userCheck) {
                AuthService.sendAuthLink(
                  values.email,
                  '/capitainerie/compte',
                ).then((data) => {
                  setRequestSent(true)
                }
                )
              } else {
                AuthService.signup(
                  values.email,
                  values.name,
                  locale
                ).then((data) => {
                  setRequestSent(true)
                }
                )
              }
            }
            }
          >
            {({
              values,
              touched,
              errors
            }) => (
              <Form>
                <Text>
                  <FormattedMessage id="account.login.notice" />
                </Text>
                <Stack
                  spacing='1.5rem'
                  mt={4}
                >
                  <Field name="email">
                    {({ field, meta, error }) => (
                      <FormControl isInvalid={meta.error && meta.touched}>
                        <FormLabel htmlFor={field.name}>
                          <FormattedMessage id="account.login.your.email" /> :
                        </FormLabel>
                        <Input
                          type="email"
                          placeholder={process.env.GATSBY_LANG === 'fr' ? 'eric@tabarly.fr' : "christophe@santa-maria.it"}
                          required
                          size="md"
                          w={{ base: 'full', lg: 'sm' }}
                          {...field}
                        />
                        <FormErrorMessage>Email invalide</FormErrorMessage>
                      </FormControl>)}
                  </Field>
                  {
                    !knownUser && userCheck ?
                      <Field name="name">
                        {({ field, meta, error }) => (
                          <FormControl>
                            <FormLabel htmlFor={field.name}>
                              <FormattedMessage id="login.form.name.and.contact" /> :
                            </FormLabel>
                            <Input
                              type="text"
                              placeholder={process.env.GATSBY_LANG === 'fr' ? 'Éric Tabarly' : 'Ellen MacArthur'}
                              required
                              size="md"
                              w='sm'
                              {...field}
                            />
                          </FormControl>
                        )}
                      </Field>
                      : null}
                  {(knownUser && userCheck && emailValue) || (!knownUser && userCheck && nameValue) ?
                    <Box mt={2}>
                      <Button
                        colorScheme="blue"
                        size="md"
                        type="submit"
                        isDisabled={true}
                        isDisabled={
                          // true
                          errors
                          || Object.keys(touched).length === 0
                        }
                      >
                        <FormattedMessage id="account.login.send.link" />
                      </Button>
                    </Box>
                    :
                    <Box mt={2}>
                      <Button
                        isDisabled={
                          errors.length
                          || Object.keys(touched).length === 0
                        }
                        colorScheme="blue"
                        size="md"
                        type="submit"
                      >
                        <FormattedMessage id="account.login.send.link" />
                      </Button>
                    </Box>}
                </Stack>
              </Form>
            )}
          </Formik>
          :
          <Box>
            <FormattedMessage id="account.login.message.sent.notice" /><span role="img" aria-label="cool">👍</span>
          </Box>
        }
      </Box>
    </Stack >
  )
}

export default GetAuthLinkForm